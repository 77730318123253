exports.LOCALES = {
  EN: "en-US",
};

exports.WEDDINGDATE = "28 Aug 2021 16:15:00 EDT";

exports.DATEOPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
};
