exports.enStrings = {
  TITLE: "Tiffany Crawford + Kevin McCurry Wedding",
  TIMELEFT: "Countdown",
  DAYS: " days ",
  HOURS: " hours ",
  MINUTES: " minutes ",
  SECONDS: " seconds ",
  TOOLTIP: "Stream will show up here when available",
  MESSAGE_1: "Thank you for attending our Virtual wedding Ceremony. We would love to have you all here celebrating in person, unfortunately the world is on fire. ",
  MESSAGE_2: "So out of respect to all of our friends, family, and their loved ones we have chosen to postpone the party celebrating our union until a time where we can gather without risk followed by an international honeymoon without a quarantine period. So please stay tuned as we update this website at a future date with that information. And we request any gifts or gestures made to celebrate us as a new couple be saved for that event as we will be at a less transient point in our lives.  ",
  MESSAGE_3: "Date TBD but Hopeful estimate  fall 2022",
};

