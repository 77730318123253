import React from "react";
import Youtube from "./Youtube";
import Count from "./Count";
import { LOCALES, WEDDINGDATE, DATEOPTIONS } from "../common/constants";
import { enStrings } from "../lang/language";
import Couple from "../images/Couple.jpg";

// Images

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { locale: LOCALES.EN, strings: enStrings };
  }

  selectLanguage = ({ locale, strings }) => {
    this.setState({ locale: locale, strings: strings });
  };

  render() {
    const weddingDateTime = new Date(WEDDINGDATE);
    const STRINGS = this.state.strings;

    return (
      <div className="App">
        <img className="Couple" src={Couple} alt="Kevin and Tiffany Portrait" />
        <div className="title">{STRINGS.TITLE}</div>
        <div className="subtitle">
          {weddingDateTime.toLocaleString(this.state.locale, DATEOPTIONS)}
        </div>
        <Count STRINGS={STRINGS} date={weddingDateTime} />
        <Youtube src="https://www.youtube.com/embed/XhLDAvqvVb8" />
        <p className="message">{STRINGS.MESSAGE_1}</p>
        <p className="message">{STRINGS.MESSAGE_2}</p>
        <p className="message">{STRINGS.MESSAGE_3}</p>
        <Youtube src="https://www.youtube.com/embed/pQcXvjYT9Ug?loop=1" />
        <a
          href="https://registry.theknot.com/--october-2022-nc/48067191"
          target="_blank"
          rel="noreferrer"
        >
          <div className="button wood">Registry</div>
        </a>
      </div>
    );
  }
}

export default App;
